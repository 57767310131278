.container,
.dataContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  width: 90%;
}

.dataContainer {
  width: 100%;
}
