.formControl {
  margin-bottom: 1.8rem;
  width: 100%;
}

.formControlWithError {
  margin-bottom: 0;
}

.error {
  margin-left: 0.2rem;
}
