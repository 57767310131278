@import 'assets/styles/colors';
@import 'assets/styles/sizes';

.container {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
.filterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  flex: 1;
  margin-left: 1rem;
}
.titleContainer {
  display: flex;
  flex-direction: column;
}
.dropdown {
  margin-left: 2.5rem;
}
.inputContainer {
  width: 160px;
}
.filterValue {
  font-size: small;
}
.itemContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}
