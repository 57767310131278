@import 'assets/styles/colors';

.header {
  position: fixed;
  align-self: center;
  background-color: $primary-white;
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 2rem;
  z-index: 100;
  height: 10%;
}

.logo {
  height: 3rem;
}
