.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  min-width: 28.125rem;
}

.image {
  height: 12.5rem;
  width: 12.5rem;
}

.inputContainer {
  width: 50%;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 90%;
  margin: 1rem;
}

.button {
  margin: 1rem;
}
