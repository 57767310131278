@import 'assets/styles/colors';

.fontFamily {
  font-family: 'Monserrat';
}

.black {
  color: $primary-black;
}

.gray {
  color: $primary-gray;
}

.white {
  color: $primary-white;
}

.violet {
  color: $primary-dark-violet;
}

.red {
  color: $primary-red;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: none;
}

.l-size {
  font-weight: 500;
  font-size: 2.2rem;
}

.m-size {
  font-weight: 500;
  font-size: 1.25rem;
}

.s-size {
  font-weight: 500;
  font-size: 1.125rem;
}

.xs-size {
  font-weight: 500;
  font-size: 0.875rem;
}

.xxs-size {
  font-weight: 500;
  font-size: 0.563rem;
}

.normal-spacing {
  letter-spacing: normal;
}

.s-spacing {
  letter-spacing: 0.081rem;
}

.m-spacing {
  letter-spacing: 0.139rem;
}

.l-spacing {
  letter-spacing: 0.417rem;
}
