@import 'assets/styles/sizes';

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 18rem;
}

@media screen and (max-width: $max-small-size) {
  .form {
    width: 90%;
  }
}
