@import 'assets/styles/colors';
@import 'assets/styles/sizes';

.spinner {
  margin: 15rem;
  align-self: center;
}

.podcastModal {
  width: 45%;
  height: auto;
  max-height: 90%;
}

.container {
  display: flex;
  justify-content: center;
  max-height: 100%;
  height: 100%;
  width: 75%;
  overflow: auto;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.title {
  text-align: center;
}

.subtitle {
  text-align: center;
  width: 45%;
  align-self: center;
  margin-top: 0;
}

.podcasts {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 2rem 0;
  width: 60rem;
  height: fit-content;
  align-self: center;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.seeMoreButton {
  border: none;
  margin-bottom: 2rem;
  color: $primary-dark-violet;
  font-weight: bold;
  font-size: 0.65rem;
  text-align: center;
}

.noPodcasts {
  display: flex;
  align-self: center;
  margin: 5rem 0;
}

.podcasts::-webkit-scrollbar {
  display: none;
}

.manipulation {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-self: center;
  align-items: center;
  width: 45%;
}

.sort {
  min-width: 45%;
  margin-right: 2rem;
}

.input {
  width: 80%;
}

@media screen and (max-width: $max-medium-size) {
  .content {
    width: 90%;
  }

  .subtitle {
    width: 90%;
  }

  .podcasts {
    width: 100%;
  }
}
