@import 'assets/styles/sizes';

.spinner {
  margin-top: 10rem;
  align-self: center;
}

.campaigns {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.campaignSummaryModal {
  width: 40%;
  min-width: 40%;
  height: 80%;
  overflow-y: auto;
}

.noCampaigns {
  text-align: center;
  margin-top: 7rem;
}
