@import 'assets/styles/sizes';
.details {
  width: 100%;
}

.information {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 50%);
}
.oldScreenshots {
  display: flex;
  flex-direction: column;
  margin-bottom: 2.75rem;
}

.oldScreenshotsContainer {
  display: flex;
  gap: 1rem;
}

.screenshot {
  max-height: 13.25rem;
  max-width: 6.25rem;
}
.screenshots {
  display: flex;
  margin-bottom: 2.75rem;
}

.screenshotsContainer {
  display: flex;
}

.screenshotAndTitleContainer {
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
}

.contact {
  display: flex;
  flex-direction: column;
}

.contactData {
  display: flex;
}

.contactItem {
  margin: 0.5rem 2rem 0.5rem 0;
}
.valueName {
  margin-bottom: 0;
}

.value {
  font-weight: 600;
  margin-top: 0.625rem;
}

@media screen and (max-width: $max-large-size) {
  .information {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(1, auto);
  }
  .contactData {
    flex-direction: column;
  }
}

@media screen and (max-width: $max-small-size) {
  .container {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .content {
    flex-direction: column;
    height: auto;
  }

  .buttonContainer {
    display: flex;
    height: 15%;
    align-self: center;
    width: 100%;
  }

  .button {
    width: 100%;
  }

  .image {
    width: 5rem;
  }

  .leftColumn {
    display: flex;
    margin-right: 0;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .description {
    width: 100%;
    margin-left: 0.5rem;
    margin-top: 0;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    max-height: 6rem;
  }

  .descriptionContainer {
    width: 100%;
  }

  .topContent {
    display: flex;
    flex-direction: column;
  }

  .screenshotsContainer {
    display: flex;
    flex-direction: column;
  }

  .screenshot {
    margin-top: 0.5rem;
  }

  .contactData {
    display: flex;
    flex-direction: column;
  }

  .contactItem {
    margin: 0;
  }
}
