@import 'assets/styles/colors';

.rc-slider-tooltip-inner {
  color: $primary-white;
  font-family: 'Monserrat';
  background: linear-gradient(
    to right,
    $primary-light-violet,
    $primary-dark-violet
  );
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.rc-slider-dot {
  width: 2px !important;
  height: 22px !important;
  left: 50.9% !important;
  bottom: -3px !important;
}

.rc-slider-step {
  height: 14px !important;
}
