@import 'assets/styles/colors';

.removeUnderline {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    text-decoration-color: $primary-dark-violet;
  }

  &:target {
    text-decoration: underline;
    text-decoration-color: $primary-dark-violet;
  }
}
