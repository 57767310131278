@import 'assets/styles/colors';

.container {
  display: flex;
  width: 90%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 1rem;
}

.rolesContainer {
  display: flex;
  width: 90%;
  height: 100%;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  margin-top: 1rem;
}

.permissionsContainer {
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  background: $primary-white;
  border: 1px solid $primary-dark-violet;
  padding: 2rem;
  width: 85%;
  div div {
    justify-content: space-between;
  }
}

.switcher input:checked + span {
  background-color: $primary-light-green !important;
}

.switcher div div .switch {
  justify-content: space-between;
}
.email {
  cursor: pointer;
}

.tabsContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.tabs {
  width: 100%;
  justify-content: center;
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  gap: 1.5rem;
  margin-top: 5rem;
}

.cashIcon {
  align-self: center;
  margin-right: 0.5rem !important;
}

.capitalize {
  text-transform: capitalize;
}

.row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  align-items: flex-end;
  margin: 1rem 0;
}

.descriptions {
  align-self: flex-start;
  width: 50%;
}

.title {
  margin: 0.375rem 0;
}

.bottomControl {
  width: 100%;
  text-align: center;
  margin-top: auto;
}

.button {
  min-width: 9rem;
  width: 30%;
}

.inputContainer {
  width: 50%;
  align-self: center;
  display: flex;
  align-items: center;
}

.dollar {
  margin-right: 0.5rem;
}

.spinnerContainer {
  margin-top: 5rem;
}

.tableButton {
  background: $primary-white;
  border: 1px solid $primary-dark-violet;
  color: $primary-black;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  transition: $primary-dark-violet 0.3s, color 0.3s;

  &:hover {
    background: $primary-dark-violet;
    color: $primary-white;
  }
}

.permissionRow {
  margin-bottom: 1rem;
}
