@import 'assets/styles/colors';

.react-datepicker__header {
  background: none;
}

.react-datepicker__input-container {
  display: flex;

  input {
    background-color: $primary-white;
    border-radius: 8px;
    color: $primary-black;
    border: 1px solid $primary-gray;
    font-size: 0.875rem;
    font-family: 'Monserrat';
    padding: 0.875rem 1rem;
    display: flex;
    width: 100%;

    &::placeholder {
      color: $primary-gray;
    }

    &:disabled {
      background-color: $secondary-gray;
    }

    &:focus {
      border: 1px solid $primary-dark-violet;
      outline: none;
    }
  }
}

.picker-error .react-datepicker__input-container {
  input {
    border: 1px solid $primary-red;
  }
}

.react-datepicker {
  font-family: 'Monserrat';
  background-color: $primary-white;
  border-radius: 8px;
  color: $primary-black;
  border-color: $primary-gray;
}

.react-datepicker__day {
  &:hover {
    color: $primary-dark-violet;
    background-color: $secondary-light-violet;
  }
}

.react-datepicker__day--keyboard-selected {
  background-color: $primary-white;
  color: $primary-black;
}

.react-datepicker__day-name {
  color: $primary-gray;
  font-weight: 600;
}

.react-datepicker__current-month {
  font-weight: 400;
}

.react-datepicker__header {
  border-bottom: 1px solid $primary-white;
}

.react-datepicker__day--selected {
  color: $primary-white;
  background-color: $primary-dark-violet;
}

.react-datepicker-wrapper {
  width: 100%;
  display: flex;
}
