// Primary Colors
$primary-dark-violet: #645aff;
$primary-light-violet: #a573ff;
$primary-gray: #b9b9b9;
$primary-black: black;
$primary-white: white;
$primary-red: #ff0000;
$primary-green: #179c00;
$primary-light-green: #6dd400;
$primary-yellow: #efec25;
$primary-pink: #eb73ff;
$primary-skyblue: #44d7b6;
$primary-orange: #f7b500;

$background-image: #050e13;

// Secondary Colors
$secondary-gray: #e8e8e8;
$secondary-dark-violet: #e2e0ff;
$secondary-light-violet: #f8f4ff;
$secondary-red: #e020204d;

// Shadow Colors
$shadow-violet: rgba(55, 125, 255, 0.06);
$shadow-red: rgba(255, 86, 48, 0.06);
$shadow-pink: rgba(234, 117, 255, 0.21);
