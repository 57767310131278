@import 'assets/styles/colors';
@import 'assets/styles/sizes';

.open {
  transform: rotate(180deg);
}

.menu {
  position: absolute;
  border: 1px solid $primary-dark-violet;
  background: linear-gradient(
    to right,
    $primary-light-violet,
    $primary-dark-violet
  );
  padding: 0.5rem;
  margin-left: -10rem;
  margin-top: 1rem;
  z-index: 100;
}

.option {
  padding: 0.5rem;
}

.optionText {
  width: max-content;
  color: $primary-white;
  margin: 0;
}

@media screen and (max-width: $max-medium-size) {
  .menu {
    position: fixed;
    right: 1rem;
  }
}
