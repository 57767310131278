@import 'assets/styles/sizes';

.container {
  display: flex;
  justify-content: center;
  max-height: 100%;
  width: 100%;
  overflow-x: hidden;
  min-height: 25rem;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  text-align: center;
}

.tabs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spinner {
  margin-top: 5rem;
  align-self: center;
}

.campaigns {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.campaignSummaryModal {
  width: 40%;
  min-width: 40%;
  height: 80%;
  overflow-y: auto;
}

.adCopyModal {
  width: 40%;
  height: 80%;
  padding-top: 1rem;
  overflow-y: auto;
}

.newCampaign {
  display: flex;
  position: fixed;
  right: 2rem;
  top: 1.5rem;
  z-index: 500;
  align-items: center;
}

.newCampaignText {
  margin-left: 0.5rem;
}

.noCampaigns {
  text-align: center;
  margin-top: 2rem;
}

.brandForm {
  display: flex;
  justify-content: center;
  margin: 1rem;
}

.selectBrand {
  width: 20rem;
}

@media screen and (max-width: $max-large-size) {
  .newCampaign {
    flex-direction: column;
    right: 1rem;
  }

  .newCampaignText {
    width: min-content;
    margin-left: 0;
  }

  .campaigns {
    width: 90%;
  }

  .selectBrand {
    width: 80%;
  }
}
