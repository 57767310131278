@import 'assets/styles/colors';

.container {
  display: flex;
  flex-direction: column;
  height: 86%;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  width: 90%;
  justify-content: center;
  align-items: center;
}

.logo {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  max-width: 10rem;
}

.imageLogo {
  width: 100%;
}

.title {
  margin: 2rem 0;
  text-align: center;
}
