@import 'assets/styles/colors';
@import 'assets/styles/sizes';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $primary-dark-violet;
  border-radius: 16px;
  margin: 0.5rem;
  padding: 1rem;
  width: 20%;
  max-width: 20%;
  position: relative;
  text-align: center;
}

.content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12rem;
}

.logo {
  max-width: 100%;
  height: 12rem;
}

.title {
  font-weight: bold;
  margin-bottom: 0;
}

.subtitle {
  width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  line-clamp: 6;
  -webkit-box-orient: vertical;
}

.buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.button {
  margin: 0.5rem 0;
  width: 100%;
  font-size: small;
}

.learnMore {
  margin: 0.5rem 0;
  margin-bottom: 2rem;
}

@media screen and (max-width: $max-small-size) {
  .container {
    width: 30%;
    max-width: 30%;
  }

  .button {
    margin: 0.5rem 0;
    width: auto;
  }
}
