@import 'assets/styles/colors';
@import 'assets/styles/sizes';

.container {
  display: flex;
  justify-content: space-between;
  width: 90%;
  padding: 2rem 0;
}

.hasBorder {
  border-top: 1px dashed $primary-gray;
}

.left {
  display: flex;
  width: 70%;
}

.right {
  display: flex;
  flex-direction: column;
  max-width: 30%;
}

.image {
  width: 5rem;
  height: 5rem;
  height: auto;
  align-self: center;
}

.info {
  display: flex;
  flex-direction: column;
  padding-left: 1.5rem;
}

.bold {
  margin: 0;
  font-weight: bold;
}

.budget {
  font-weight: bold;
}

.adTypes {
  display: flex;
  flex-flow: wrap;
}

.selectedAdType {
  display: flex;
  margin-right: 1.5rem;
  align-items: center;
  margin-bottom: 0.5rem;
}

.selected {
  width: 1.2rem;
  height: 1rem;
  background: linear-gradient(
    to right,
    $primary-light-violet,
    $primary-dark-violet
  );
  border-radius: 5px;
  margin-right: 0.5rem;
}

.offerStatus {
  display: flex;
  align-items: center;
}

.adCopy {
  display: flex;
  margin-top: 1rem;
  padding: 0.5rem 1.5rem;
  align-items: center;
  background: $secondary-light-violet;
}

.noWrap {
  white-space: nowrap;
}

.status {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 10px;
  margin-right: 0.5rem;
}

.green {
  background-color: $primary-light-green;
}

.yellow {
  background-color: $primary-yellow;
}

.orange {
  background-color: $primary-orange;
}

.red {
  background-color: $primary-red;
}

.rejectedContainer {
  background-color: $secondary-red;
  margin-bottom: 1.5rem;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.newPodcastButton {
  border: none;
  margin-left: 1rem;
  min-width: max-content;

  &:hover {
    background-color: $secondary-red;
    color: $primary-white;
  }
}

.rejected {
  width: 100%;
  margin-bottom: 1rem;
}

.topText {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  padding-left: 1rem;
}

.bottomText {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1rem;
  font-family: 'Monserrat';
}

.reason {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: 0.875rem;
}

.reasonTitle {
  margin-right: 0.5rem;
  margin-top: 0;
  margin-bottom: 0;
  width: auto;
  display: flex;
  font-weight: bold;
}

.rejectDate {
  margin-bottom: 0.2rem;
  margin-top: 0;
  font-weight: 100;
}

@media screen and (max-width: $min-medium-size) {
  .noWrap {
    white-space: unset;
  }

  .adTypes {
    flex-direction: column;
  }

  .selectedAdType {
    margin-right: 0;
    align-items: left;
    margin-bottom: 0.5rem;
  }

  .info {
    padding: 0;
    margin-top: 1rem;
  }

  .left {
    flex-direction: column;
    width: 100%;
  }

  .right {
    position: absolute;
    right: 0;
    margin-right: 10%;
    width: max-content;
    max-width: 100%;
  }

  .image {
    align-self: flex-start;
  }

  .container {
    width: 80%;
  }
}
