@import 'assets/styles/colors';
@import 'assets/styles/sizes';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
}
.notFoundContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  justify-content: center;
}
.information {
  display: grid;
  grid-template-rows: repeat(5, minmax(1rem, auto));
  grid-auto-flow: column;
  grid-auto-columns: 40%;
  gap: 1.5rem;
  justify-content: right;
}

.item {
  display: flex;
  flex-direction: column;
  width: 95%;
  border-left: 1px solid $primary-dark-violet;
  padding-left: 0.5rem;
  height: fit-content;
}

.descriptionItem {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  width: 94%;
  border-left: 1px solid $primary-dark-violet;
  padding-left: 0.5rem;
  height: fit-content;
  margin-left: 8%;
}

.itemTitle {
  margin-top: 0;
}

.informationText {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold;
}

.category {
  display: flex;
  width: min-content;
  margin-top: 0;
}

.rating {
  width: 1.5rem;
}

.title {
  align-self: center;
  margin-bottom: 2rem;
  margin-top: 0;
}

.removeMargin {
  justify-content: center;
}

.categories {
  display: flex;
  width: max-content;
}

.green {
  color: $primary-green;
}

@media screen and (max-width: $max-medium-size) {
  .information {
    grid-template-rows: repeat(9, minmax(1rem, auto));
    grid-auto-columns: 100%;
    gap: 1rem;
  }

  .descriptionItem {
    width: 100%;
    margin-top: 1rem;
    padding-bottom: 1rem;
    margin-left: 0;
  }

  .informationTextDescription {
    padding-bottom: 1rem;
    font-weight: normal !important;
  }
}
