@import 'assets/styles/colors';

.container {
  display: flex;
}

.leftContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.rightContainer {
  margin-left: 2.5rem;
}

.podcastImg {
  height: 5rem;
  width: 5rem;
}

.dates {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.date {
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.podcastName {
  font-weight: bold;
}

.dateValue {
  font-weight: bold;
}

.flagged {
  color: $primary-red;
}

.notLast {
  border-bottom: 1px dashed $secondary-gray;
  margin-bottom: 1rem;
}
