@import 'assets/styles/colors';
@import 'assets/styles/sizes';

.tableWrapper {
  white-space: nowrap;
  background-color: $primary-white;
  overflow-y: unset;
  height: 100%;
  width: 90%;
}

.table {
  border-spacing: 0;
  text-align: center;
  width: 100%;
}

.thead {
  position: sticky;
  top: 0;
  background-color: $primary-white;
  z-index: 10;
}

.th {
  padding: 1rem;
  padding-left: 0;
}

.thBody {
  text-align: left;
}

.td {
  padding: 1rem 0rem;
  border-bottom: 0.1rem dashed $secondary-gray;
  white-space: normal;
  max-width: 8rem;
}

.noData {
  width: 100%;
  margin: 2rem 0rem;
}

.tdBody {
  text-align: left;
  padding: 0 1rem;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    overflow: visible;
    word-wrap: break-word;
  }
}

.tr {
  background-color: $primary-white;
}

.payButtonCell {
  text-align: left;
  padding-left: 5rem;
}

.payButton {
  width: 100%;
}

.payButtonMargin {
  margin-bottom: 0.3rem;
}

.paymentButtons {
  display: flex;
  flex-direction: column;
}

.open {
  transform: rotate(180deg);
}

.headerContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.arrow {
  margin: 0 0.5rem;
  width: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: $max-medium-size) {
  .tableWrapper {
    width: 100%;
  }

  .td {
    max-width: 15rem;
  }
}
