@import 'assets/styles/colors';

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0.7rem;
  width: 100%;
  margin-top: 1.5rem;
}

.isDisabled {
  background-color: $secondary-gray !important;
  border-color: $primary-gray;
  box-shadow: none;
  color: $primary-gray;
}

.label {
  font-size: 0.875rem;
  font-weight: bold;
}

.switch {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.switch input:checked + .slider {
  background-color: $primary-dark-violet;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px $primary-light-violet;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
