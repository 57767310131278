.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  max-height: 10rem;
}

.reason {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reasonButton {
  min-width: 15rem;
  margin: 0.5rem;
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}

.button {
  margin: 0.5rem;
  width: 45%;
  max-width: 18.5rem;
}

.textareaContainer {
  width: 85%;
  height: auto;
  margin: 1rem;
}

.textarea {
  height: 100%;
}
