@import 'assets/styles/sizes';

.container {
  display: flex;
  justify-content: center;
  max-height: 100%;
  width: 100%;
  overflow-x: hidden;
  min-height: 25rem;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
}

.title {
  padding-top: 10px;
  margin-bottom: 0px;
}
