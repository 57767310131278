@import 'assets/styles/colors';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.isDisabled {
  background-color: $secondary-gray !important;
  border-color: $primary-gray;
  box-shadow: none;
  color: $primary-gray;
}

.labelText {
  margin-left: 0.25rem;
}

.input {
  font-family: 'Monserrat';
  border-radius: 8px;
  color: $primary-black;
  border: 1px solid $primary-gray;
  padding: 0.875rem 1.063rem;
  background: $primary-white;
  box-shadow: 0 12px 23px $shadow-violet;

  &::placeholder {
    color: $primary-gray;
  }

  &:focus {
    border: 1px solid $primary-dark-violet;
    color: $primary-black;
    outline: none;
  }
}

.hasError {
  border: 1px solid $primary-red;
  outline: none;
  box-shadow: 0 12px 23px $shadow-red;

  &:focus {
    outline: none;
  }
}

.readOnly {
  &:hover {
    cursor: auto;
  }

  &:focus {
    cursor: auto;
    outline: none;
    color: $primary-gray;
  }
}
