@import 'assets/styles/colors';
@import 'assets/styles/sizes';

.container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-left: 2em;
}

.tabs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ul {
    position: fixed;
    z-index: 100;
    top: 7em;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.header {
  display: flex;
}

.divider  {
  width: 100%;
  height: 0.5rem;
  color: $primary-gray;
}

.spinner {
  display: flex;
  height: 100%;
  margin-top: 10rem;
}

.payButton {
  width: 100%;
}

.payButtonMargin {
  margin-bottom: 0.3rem;
}

.paymentButtons {
  display: flex;
  flex-direction: column;
}

.status {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 10px;
  margin-right: 0.5rem;
}

.accepted {
  background-color: $primary-light-green;
}

.partial {
  background-color: $primary-skyblue;
}

.pending {
  background-color: $primary-orange;
}

.rejected {
  background-color: $primary-red;
}

.externally-paid {
  background-color: $primary-black;
}

.tableContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.exportButtonContainer {
  position: fixed;
  top: 4em;
  z-index: 100;
  right: 0em;
}

.exportButton {
  align-self: flex-end;
  margin-right: 1rem;
  text-decoration: none;
}

.createButtonContainer {
  position: fixed;
  top: 4em;
  z-index: 100;
  right: 8em;
}

.createButton {
  align-self: flex-end;
  margin-right: 1rem;
  text-decoration: none;
}

.closed {
  background-color: $primary-dark-violet;
}
@media screen and (max-width: $max-medium-size) {
  .container {
    overflow-x: scroll;
    width: 90%;
  }
}
