@import 'assets/styles/colors';
@import 'assets/styles/sizes';

.content {
  width: 20rem;
  min-width: fit-content;
  height: 95%;
  display: flex;
  border: solid $primary-light-violet 1px;
  margin: 1rem;
  margin-right: 0;
  flex-direction: column;
  background-color: $primary-white;
  z-index: 800;
  position: fixed;
}

.iconButton {
  display: flex;
  flex-direction: column;
  margin: 2rem;
  background: none;
  border: none;
  z-index: 200;
  position: fixed;

  &:hover {
    cursor: pointer;
  }
}

.menuIcon {
  width: 1.5rem;
  height: 0.1rem;
  border-top: 1px solid $primary-light-violet;
  margin: 0.2rem;
}

.header {
  display: flex;
  align-items: center;
}

.closeButton {
  display: flex;
  height: 2rem;
  width: 2rem;
  background: none;
  border: none;
  align-self: flex-start;
  margin: 1rem;

  &:hover {
    cursor: pointer;
  }
}

.open {
  visibility: visible;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-duration: 600ms;
  opacity: 1;
}

.close {
  visibility: hidden;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-duration: 600ms;
  transform: translateX(-100%);
  opacity: 0;
}

.background {
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  background-color: $primary-white;
  opacity: 0.8;
  z-index: 700;
  position: fixed;
  overflow: hidden;
}

@media screen and (max-width: $max-medium-size) {
  .content {
    width: 90%;
  }
}
