@import 'assets/styles/colors';
@import 'assets/styles/sizes';

.container {
  display: flex;
  border-bottom: 1px dashed $secondary-gray;
  margin: 1rem 0;
  padding: 1rem 0;
}

.content {
  height: 100%;
  width: 100%;
  display: flex;
}

.image {
  width: 5rem;
  max-height: 5rem;
  margin-bottom: 1rem;
}

.brand {
  margin-left: 2rem;
}

.logo {
  max-width: 100%;
}

.title {
  font-weight: bold;
  margin: 0;
  margin-bottom: 2rem;
  text-align: left;
}

.subtitle {
  width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  line-clamp: 6;
  -webkit-box-orient: vertical;
}

.buttons {
  display: flex;
  flex-direction: column;
  width: 20rem;
  align-items: center;
  margin-left: 2rem;
}

.button {
  margin: 0.5rem 0;
  width: 100%;
}

.item {
  display: flex;
  flex-direction: column;
  width: 95%;
  border-left: 1px solid $primary-dark-violet;
  padding-left: 0.5rem;
  height: fit-content;
}

.itemTitle {
  margin-top: 0;
}

.information {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-auto-flow: column;
  gap: 1.5rem;
}

.informationText {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold;
}

.mediaIcon {
  width: 1.5rem;
  margin-right: 0.5rem;
}

.links {
  display: flex;
}

.email {
  width: 10rem;
}

@media screen and (max-width: $max-small-size) {
  .container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .buttons {
    margin: 1rem 0;
    width: 80%;
  }

  .title {
    text-align: center;
  }

  .brand {
    margin-left: 0;
  }
}
