@import 'assets/styles/colors';
@import 'assets/styles/sizes';

.content {
  min-width: fit-content;
  min-height: fit-content;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  border: solid $primary-light-violet 1px;
  border-radius: 16px;
  flex-direction: column;
  background-color: $primary-white;
  z-index: 600;
  padding: 2rem;
}

.background {
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  background-color: $primary-white;
  opacity: 0.8;
  z-index: 500;
  position: fixed;
  overflow: hidden;
}

.open {
  visibility: visible;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-duration: 400ms;
  opacity: 1;
}

.close {
  visibility: hidden;
  opacity: 0;
}

.closeIcon {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
  display: flex;
  justify-content: flex-end;
}

.closeImg {
  width: 60%;
}

@media screen and (max-width: $max-large-size) {
  .content {
    min-width: auto;
    width: 70% !important;
  }
}
