@import 'assets/styles/colors';

.button {
  font-family: 'Monserrat';
  border: none;
  cursor: pointer;
  transition: background-color 125ms linear;
  padding: 0 1rem;

  &:active {
    opacity: 0.8;
  }
}

.upperCase {
  text-transform: uppercase;
}

.isFullWidth {
  width: 100%;
}

.primary {
  border-radius: 25px;
  background: linear-gradient(
    to right,
    $primary-light-violet,
    $primary-dark-violet
  );
  color: $primary-white;

  &:hover {
    border: 1.6px solid $primary-dark-violet;
    box-shadow: 3.2px 3.2px 3.2px 3.2px $secondary-dark-violet;
  }
}

.secondary {
  width: fit-content;
  border-radius: 5px;
  background: $primary-white;
  border: 1px solid $primary-dark-violet;
  color: $primary-black;

  &:hover {
    background: $secondary-dark-violet;
  }

  &.loading:hover {
    background: $primary-white;
  }
}

.red {
  border: 1px solid $primary-red;
  color: $primary-red;

  &:hover {
    background: $secondary-red;
  }
}

.pink {
  background: linear-gradient(to right, $primary-pink, $primary-orange);

  &:hover {
    border: 1.6px solid $primary-pink;
    box-shadow: 3.2px 3.2px 3.2px 3.2px $shadow-pink;
  }
}

.green {
  background: linear-gradient(to right, $primary-green, $primary-light-green);

  &:hover {
    border: 1.6px solid $primary-light-green;
    box-shadow: 3.2px 3.2px 3.2px 3.2px $primary-light-green;
  }
}

.black {
  background: linear-gradient(to right, $primary-black, $primary-gray);

  &:hover {
    border: 1.6px solid $primary-gray;
    box-shadow: 3.2px 3.2px 3.2px 3.2px $primary-gray;
  }
}

.large {
  font-size: 1.125rem;
  font-weight: 500;
  height: 2.5rem;
  letter-spacing: 0.139rem;
}

.small {
  font-size: 0.563rem;
  font-weight: 500;
  height: 1.5rem;
  letter-spacing: 0.081rem;
}

.disabled {
  &:hover {
    cursor: not-allowed;
  }
}

.spinner {
  display: flex;
  justify-content: center;
}
