.container {
  width: 100%;
  margin-bottom: 2.2rem;
}

.content {
  display: flex;
  align-items: center;
}

.startText {
  margin-right: 1rem;
  width: 25%;
  text-align: end;
}

.endText {
  margin-left: 1rem;
  width: 25%;
  text-align: start;
}
