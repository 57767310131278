.container {
  display: flex;
  flex-direction: column;
  min-width: 25rem;
}

.title {
  font-weight: bold;
  align-self: center;
}

.titles {
  display: flex;
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  width: fit-content;
  margin: 1rem;
}

.toPay {
  display: flex;
  align-items: center;
  justify-content: center;
}

.name {
  align-self: center;
}

.number {
  margin: 2rem;
  margin-bottom: 0;
  width: 50%;
  align-self: center;
}

.valueTitles {
  margin-bottom: 1rem;
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputContainer {
  width: 50%;
}
