.container {
  margin-bottom: 1.8rem;
  width: 100%;
}

.error {
  margin-bottom: 0;
}

.top {
  display: flex;
  flex-direction: column;
}

.label {
  margin-left: 0.25rem;
  margin-right: 0.5rem;
}

.textarea {
  display: flex;
  flex-direction: column;
}

.errorText {
  margin-left: 0.2rem;
}
