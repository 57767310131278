@import 'assets/styles/sizes';

.container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
  max-height: 90vh;
}

.content {
  width: 100%;
  display: flex;
  height: auto;
  flex-direction: column;
}

.topContent {
  width: 100%;
  height: auto;
  max-height: 70%;
  display: flex;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  width: 12rem;
  margin-right: 2rem;
  height: inherit;
}

.rightColumn {
  display: flex;
  flex-direction: column;
  width: inherit;
}

.bottomContent {
  width: 100%;
}

.image {
  width: 12rem;
}

.media {
  height: 1.2rem;
  display: flex;
  margin-top: 0.5rem;
}

.mediaIcon {
  display: flex;
  margin-right: 0.5rem;
  width: 1.2rem;
}

.buttonContainer {
  display: flex;
  height: 15%;
  justify-content: center;
}

.button {
  width: 35%;
  margin: 1rem 0;
  margin-right: 1rem;
}

.title {
  font-weight: bold;
  margin: 0.5rem;
  margin-left: 0.2rem;
}

.description {
  max-height: 18rem;
  height: fit-content;
}

.badge {
  display: flex;
  flex-flow: wrap;
  white-space: nowrap;
}

.rating {
  width: 1.5rem;
}

.tabs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ul {
    p {
      font-size: small;
    }
  }
}
.tabsContainer {
  display: inline;
}

@media screen and (max-width: $max-small-size) {
  .container {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .content {
    flex-direction: column;
    height: auto;
  }

  .buttonContainer {
    display: flex;
    height: 15%;
    align-self: center;
    width: 100%;
  }

  .button {
    width: 100%;
  }

  .image {
    width: 5rem;
  }

  .leftColumn {
    display: flex;
    margin-right: 0;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .description {
    width: 100%;
    margin-left: 0.5rem;
    margin-top: 0;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    max-height: 6rem;
  }

  .descriptionContainer {
    width: 100%;
  }

  .topContent {
    display: flex;
    flex-direction: column;
  }

  .contactData {
    display: flex;
    flex-direction: column;
  }

  .contactItem {
    margin: 0;
  }
}
