@import 'assets/styles/colors';

.dropdown {
  margin-left: 13rem;
  margin-top: -2.5rem;
  position: fixed !important;
  .menu {
    position: relative !important;
  }
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-width: 30rem;
}
.form {
  width: 84%;
  align-items: center;
  display: flex;
  flex-direction: column;
}
form .pay {
  margin-top: 1rem;
  width: 15rem;
  :disabled {
    background-color: $primary-gray;
  }
}

.row {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  input {
    height: 0.2rem;
  }
}

.inputContainer {
  // width: 15rem;
  justify-content: center;
}
.column {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.create-payment__button-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
}

.button {
  margin: 0.5rem;
  cursor: pointer;
  min-width: 15rem;
  min-height: 2rem;
  &--selected {
    background-color: $primary-light-violet;
    color: $primary-white;
  }

  &:hover {
    background-color: $primary-white;
  }

  &--selected:hover {
    background-color: $primary-dark-violet;
  }
}
