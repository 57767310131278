@import 'assets/styles/colors';

.container {
  display: flex;
  flex-direction: column;
}

.title {
  font-weight: bold;
}

.actions {
  display: flex;
  align-self: flex-end;
  margin-top: 1rem;
}

.button {
  width: fit-content;
  margin-left: 1rem;
  margin-right: 1rem;
}

.exportButtonLink {
  text-decoration: none;
  color: $primary-white !important;
}
