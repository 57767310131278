@import 'assets/styles/sizes';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin-top: 0;
  text-align: center;
}

.logo {
  width: 5rem;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 1rem;
}

.notesText {
  margin-top: 0;
}

.button {
  margin-top: 1.5rem;
  width: 40%;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media screen and (max-width: $min-medium-size) {
  .button {
    width: 100%;
  }
}
