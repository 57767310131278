.button {
  display: flex;
  height: fit-content;
  width: fit-content;
  background: none;
  border: none;

  &:hover {
    cursor: pointer;
  }
}

.disabled {
  &:hover {
    cursor: not-allowed;
  }
}
