@import 'assets/styles/colors';
@import 'assets/styles/sizes';

.container {
  border: 1px solid $primary-dark-violet;
  border-radius: 10px;
  margin-top: 1rem;
  width: 100%;
  transition: all 0s ease-in-out 0.6s;
  transition: border 0s ease-in 0.4s;
}

.paidOffers {
  &:empty {
    padding-bottom: 1rem;
  }
}

.content {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  padding-bottom: 0;
}

.campaignDetails {
  display: flex;
}

.title {
  font-weight: bold;
  margin: 0;
}

.content {
  display: flex;
}

.details {
  display: flex;
  flex-direction: column;
  border-left: 1px solid $primary-dark-violet;
  padding-left: 0.5rem;
  margin: 1rem 3rem;
  margin-left: 0;
}

.text {
  margin: 0;
}

.budgetAmount {
  margin-top: 0.5rem;
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.seeMore {
  display: flex;
  height: fit-content;
  align-items: center;
  padding: 0;
  margin-top: 0.5rem;
}

.icon {
  width: 20%;
  margin-right: 0.5rem;
}

.expand {
  display: flex;
  justify-content: center;
}

.expandText {
  color: $primary-dark-violet;
}

.closeIcon {
  transform: rotate(180deg);
  width: 25%;
}

.expanded {
  margin-top: 0;
  width: 100%;
  background-color: $secondary-light-violet;
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  transition: all 0.6s ease-in-out;
  overflow: hidden;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.visible {
  max-height: 40rem;
  visibility: visible;
  opacity: 1;
  overflow-y: scroll;
  transition: all 0.6s ease-in-out;
}

.offerStatus {
  display: flex;
  align-items: center;
}

.status {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 10px;
  margin-right: 0.5rem;
}

.green {
  background-color: $primary-light-green;
}

.yellow {
  background-color: $primary-yellow;
}

.orange {
  background-color: $primary-orange;
}

.red {
  background-color: $primary-red;
}

.noMargin {
  margin: 0;
}

@media screen and (max-width: $max-medium-size) {
  .campaignDetails {
    flex-direction: column;
  }

  .noMargin {
    margin: 0;
  }
}
