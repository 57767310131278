@import 'assets/styles/colors';
@import 'assets/styles/sizes';

.spinner {
  margin: 15rem;
  align-self: center;
}

.container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.title {
  text-align: center;
}

.podcasts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2rem 0;
  width: 50%;
  align-self: center;
}

.noPodcasts {
  display: flex;
  align-self: center;
  margin: 5rem 0;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: $min-medium-size) and (max-width: $max-large-size) {
  .content {
    width: 70%;
  }

  .podcasts {
    width: 100%;
  }
}

@media screen and (max-width: $max-small-size) {
  .content {
    width: 90%;
  }

  .podcasts {
    width: 100%;
  }
}
