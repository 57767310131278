@import 'assets/styles/sizes';

.item {
  display: flex;
  margin: 0.5rem 1rem;
}

.itemTitle {
  margin-top: 0;
}

.icon {
  display: flex;
  margin-right: 1rem;
}

.informationText {
  font-weight: 600;
}

.badge {
  display: flex;
  flex-flow: wrap;
  white-space: nowrap;
}

.unorderedList {
  margin-block-start: 0;
  padding-inline-start: 0.125rem;
}

.reversedColumn {
  display: flex;
  flex-direction: column-reverse;
}

.multipleColumnsWrapper {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: 50% 50%;
}

.editableWrapper {
  display: flex;
  justify-content: space-between;
}

.editButton {
  margin-left: 1rem;
}

@media screen and (max-width: $max-large-size) {
  .multipleColumnsWrapper {
    display: contents;
  }
}
