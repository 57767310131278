@import 'assets/styles/colors';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.labelText {
  margin-left: 0.25rem;
  margin-top: 0;
}

.textarea {
  resize: none;
  font-family: 'Monserrat';
  border-radius: 8px;
  color: $primary-black;
  border: 1px solid $primary-gray;
  padding: 0.875rem 1.063rem;
  background: $primary-white;
  box-shadow: 0 12px 23px rgba(55, 125, 255, 0.06);
  overflow-y: scroll;

  &::placeholder {
    color: $primary-gray;
  }

  &:focus {
    outline: 1px solid $primary-dark-violet;
    color: $primary-black;
  }
}

.hasError {
  border: 1px solid $primary-red;
  outline: none;
  box-shadow: 0 12px 23px rgba(255, 86, 48, 0.06);

  &:focus {
    outline: none;
  }
}

.isDisabled {
  border-color: $primary-gray;
  box-shadow: none;
  color: $primary-gray;
}

.XS {
  min-height: 2rem;
  max-height: 2rem;
}

.S {
  min-height: 5rem;
  max-height: 5rem;
}

.M {
  min-height: 15rem;
  max-height: 15rem;
}

.readOnly {
  &:hover {
    cursor: auto;
  }

  &:focus {
    cursor: auto;
    outline: none;
  }
}
