@import 'assets/styles/colors';
@import 'assets/styles/sizes';

.container {
  border: 1px solid $primary-dark-violet;
  border-radius: 10px;
  margin-top: 1rem;
  margin-left: -1rem;
  width: 100%;
  transition: all 0s ease-in-out 0.6s;
  transition: border 0s ease-in 0.4s;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0.1rem;
}

.campaignDetails {
  display: flex;
  align-items: center;
  margin: 0.5rem 0rem 1rem 1.5rem;
}

.title {
  font-weight: bold;
  margin-left: 0.5rem;
}

.details {
  display: flex;
  flex-direction: column;
  border-left: 1px solid $primary-dark-violet;
  padding-left: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.detailsEpisodes {
  margin-right: 1rem;
  text-align: center;
}
.paidDetails {
  margin-left: 0.5rem;
}

.text {
  margin: 0;
}

.paid {
  color: red;
}
.budgetAmount {
  margin-top: 0.5rem;
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.seeMore {
  display: flex;
  align-items: center;
  margin-top: -5rem;
}

.seeMoreContainer {
  margin-left: 1rem;
  display: flex;
  justify-content: space-between;
}

.imageAdFormatContainer {
  display: flex;
  justify-content: space-between;
}

.icon {
  width: 20%;
  margin-right: 0.5rem;
}

.closeIcon {
  transform: rotate(180deg);
  width: 25%;
}

.image {
  width: 5rem;
  margin: 1rem;
}

.logo {
  max-width: 100%;
}
.episodes {
  width: 100%;
  border-radius: 5px;
  background: white;
  border: 1px solid #645aff;
  color: black;
  padding: 0.3em;
}
.adFormatContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  right: 0rem;
  height: 30px;
  border-radius: 0 8px 0 0;
  top: 0rem;
  width: 40%;
  padding: 0.1rem;
  background: linear-gradient(
    to right,
    $primary-light-violet,
    $primary-dark-violet
  );
  .title {
    font-size: 12px;
    color: white;
    font-weight: normal;
  }
}

@media screen and (max-width: $max-medium-size) {
  .campaignDetails {
    flex-direction: column;
  }
}
