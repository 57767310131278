@import 'assets/styles/colors';

.tabs {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  border-bottom: 1px solid $primary-white;
  color: $primary-dark-violet;
}

.tab {
  margin: 0 1rem;
  padding: 0.5rem;
}

.button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.current {
  border-bottom: 2px solid $primary-dark-violet;
}

.currentText {
  font-weight: bold;
}

.buttonText {
  margin: 0;
}
